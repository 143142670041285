import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    const date = new Date();

    return (
        <footer>
            <div className="bliz-footer">
                <div className="copyright">
                    <p>
                        Copyright © {date.getFullYear()} <strong>BlizLoans</strong>. All Rights Reserved
                    </p>
                </div>
                <div className='social-media'>
                    <Link to="//www.twitter.com/" target='_blank' smooth="true">
                        <i className="fa-brands fa-twitter"></i>
                    </Link>
                    <Link to={"//www.facebook.com/"} target='_blank' smooth="true">
                        <i className="fa-brands fa-facebook"></i>
                    </Link>
                    <Link to={"//www.instagram.com/"} target='_blank' smooth="true">
                        <i className="fa-brands fa-instagram"></i>
                    </Link>
                    <Link to={"//www.youtube.com/"} target='_blank' smooth="true">
                        <i className="fa-brands fa-youtube"></i>
                    </Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer