import React from "react";

const FeatureBox = ({ image, title, content }) => {

  return (
    <div className="f-box">
      <div className="f-img">
        <i><img src={image} alt="" width="150" height="62" /></i>
      </div>
      <div className="">
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
    </div>
  )
}

export default FeatureBox;