import React from "react";
import FeatureBox from "./FeatureBox";
import featureimage from "../img/feature_1.svg";
import featureimage2 from "../img/feature_2.svg";
import featureimage3 from "../img/feature_3.svg";
import featureimage4 from "../img/feature_4.svg";
import featureimage5 from "../img/feature_5.svg";

const Feature = () => {
  return (
    <div id="features">
      <h2>Features</h2>
      <div className="f-container">
        <FeatureBox
          image={featureimage}
          title="Process Loan Application"
          content="With blizloans, you can collect loan applications 
                        from customers and also collect their KYC details"
        />

        <FeatureBox
          image={featureimage2}
          title="Credit scoring"
          content="Assess your customer credit worthiness with 
                        artificial  intelligence and credit bureau data"
        />

        <FeatureBox
          image={featureimage3}
          title="Debt recovery"
          content="Allow debt recovery partner to recover unpaid loans debt from your customer"
        />

        <FeatureBox
          image={featureimage4}
          title="One click Loan disbursement"
          content="Disburse loan to your customer from your card or 
                        bank account with a single click once a loan is approved"
        />

        <FeatureBox
          image={featureimage5}
          title="Repayment collection"
          content="Collect repayment from your customer card or bank account with direct debit"
        />
      </div>
    </div>
  )
}

export default Feature;