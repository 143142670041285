import React from "react";
import Navbar from './component/Navbar'
import Feature from "./component/Feature";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Presentation from "./component/Presentation";
import Privacy from "./component/Privacy";
import './style.css';

const App = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <Header />
        <Feature />
        <Privacy />
        <Presentation />
      </div>
      <Footer />
    </>
  )
}

export default App;