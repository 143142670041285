import React from "react";

const Presentation = () => {
    return (
        <div id="presentation">
            <div className="content">
                <h2>How it works</h2>
                <h3>Lender:</h3>
                <p>
                    1. Register Blizfinance <br />
                    2. Customize your site logo and loan product <br />
                    3. Share your site links with your customer <br />
                    4. Approve loans and track your payment <br />
                </p>

                <h3>Borrower:</h3>
                <p>
                    1. Access lenders site <br />
                    2. Complete loan application process <br />
                    3. Add card for repayment <br />
                </p>
            </div>
        </div>
    )
}

export default Presentation