import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../img/logo.svg';


const Navbar = () => {
    const [nav, setNav] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 50) {
            setNav(true);
        } else {
            setNav(false);
        }
    }

    window.addEventListener('scroll', changeBackground);
    return (
        <nav className={nav ? 'nav active' : 'nav'}>
            <div className="bliz-nav">
                <Link className="logo">
                    <img src={logo} alt="" />
                </Link>
                <input type="checkbox" className="menu-btn" id="menu-btn" />
                <label htmlFor="menu-btn" className="menu-icon">
                    <span className="nav-icon"></span>
                </label>
                <ul className="menu">
                    <li className="login">
                        <Link>Login</Link>
                    </li>
                    <li className="register">
                        <Link>REGISTER</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar;