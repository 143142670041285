import React from "react";

import privacy from '../img/privacy.svg'

const Privacy = () => {
    return (
        <div id="privacy">
            <div className="privacy-title">
                <h2>Privacy & Security</h2>
                <h3>We care about your data privacy and security </h3>
            </div>
            <div className="privacy-container">
                <div className="privacy-text">
                    <p>
                        1. You own your data <br />
                        2. We protect all customers data transmitted to our server over public network with strong Transport Layer Security (TLS 1.2/1.3) encryption with strong cyphers <br />
                        3. We encrypt customer data using 256-bit Advanced Encryption Standard (AES)
                    </p>
                </div>
                <div className="privacy-img">
                    <img src={privacy} alt="privacy" />
                </div>
            </div>
        </div>
    )
}

export default Privacy;