import React from "react";

import hero from '../img/hero.svg';

const Header = () => {
    return (
        <div id="main">
            <div className="left-main">
                <h1>Simplified Lending Solution</h1>
                <p className="details">Blizloans allows you to acquire borrowers, assess their credit score, disburse loans and process repayment</p>
            </div>
            <div className="right-main">
                <img src={hero} className="img-hero" alt="" />
            </div>
        </div>
    )
}

export default Header;